<!--
 * @Description: 修改车辆 alterPlateNumber
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-07 13:40:01
 * @LastEditors: zhoucheng
-->
<template>
  <div class='main'>
    <van-nav-bar title="车牌修改"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="main-content">
      <div class="main-box">
        <div class="content">
          <!-- v-if="parkTypeCode==='1'" -->
          <van-row class="title">车牌图片</van-row>
          <van-uploader class="carBG"
                        v-model="fileList"
                        :after-read="afterRead"
                        :before-read="beforeRead"
                        @delete="delImg"
                        max-count="1">
            <div style="width:100%;height:100%">
              <van-image :src="require('@/assets/signIn/carimg.png')"
                         v-if="!entranceImg"></van-image>
              <div v-else>
                <img style="width:100%;height:100%"
                     :src="entranceImg"
                     alt="">
              </div>
            </div>
          </van-uploader>
          <!-- 车牌颜色绑定弹出层 -->
          <van-row class="title">车牌颜色</van-row>
          <van-field v-model="plateColor"
                     placeholder="请选择车牌颜色"
                     readonly
                     @click="plateColorClick" />
          <van-action-sheet v-model="plateColorShow"
                            :actions="plateColorList"
                            @select="onSelectPlateColor" />
          <!-- 车牌号 -->
          <van-row class="title">车牌号</van-row>
          <div :class="!popShow? 'platInput-box':'platInput-box2'">
            <plate-number-input ref="numInput"
                                v-model="plateNumber"
                                @idxChange='handleClickChangeIndex'
                                @currentPlateNumber='handleShowPlateNumber' />
          </div>
          <!-- 泊位号 -->
          <van-row class="title"
                   v-if="parkTypeCode==='1'">泊位号</van-row>
          <van-field v-if="parkTypeCode==='1'"
                     v-model="parkSpaceNumber"
                     placeholder="请选择泊位号"
                     readonly
                     @click="placeNumberClick" />
          <van-action-sheet style="height:40%"
                            v-model="placeNumberShow"
                            :actions="placeNumberList"
                            @select="onSelectPlaceNumber" />
          <!-- <van-row class="title"
                   v-if="parkTypeCode==='1'">泊位号</van-row>
          <van-field v-if="parkTypeCode==='1'"
                     v-model="formList.parkSpaceNumber"
                     placeholder="请选择泊位号"
                     clearable
                     disabled /> -->
        </div>
      </div>
      <div :class="popShow?'pop-placeholder1':'pop-placeholder2'"></div>
    </div>
    <!-- 汉字字母键盘 -->
    <keyborad-button class="keyboradButton"
                     ref="keyboard"
                     v-show="popShow"
                     @handleClickClose="handleClickClose"
                     @click="handleKeyboardClick" />
    <div class="but-box">
      <van-button @click="handleClickConfirm"
                  :disabled="buttonDisable">确认</van-button>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import plateNumberInput from '@/components/plateNumber/plateNumberInput'
import keyboradButton from '@/components/plateNumber/keyboradButton'
import Compressor from 'compressorjs'
import { Toast } from 'vant'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    plateNumberInput,
    keyboradButton
  },
  data () {
    // 这里存放数据
    return {
      parkTypeCode: '', // 停车场类型
      fileList: [], // 图片列表
      entranceImg: '', // 进场图片
      plateColor: '', // 车牌颜色
      plateColorShow: false, // 车牌颜色选择弹窗
      plateColorList: [],
      plateColorObj: {},

      plateNumber: '',
      popShow: false, // 车牌组件弹窗

      parkSpaceNumber: '', // 泊位号
      placeNumberShow: false,
      placeNumberList: [],
      placeNumberObj: {},

      formList: {},
      buttonDisable: true
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'formList.plateNumber' () {
      if (this.formList.plateNumber.length < 7) {
        this.buttonDisable = true
      }
      if (this.formList.plateNumber.length >= 7) {
        this.buttonDisable = false
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryPlateColor()
    this.queryParkPlaceNumber()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.parkTypeCode = this.$route.query.parkTypeCode
    this.plateColor = this.$route.query.alterPlateNumberList.plateColor
    this.parkSpaceNumber = this.$route.query.alterPlateNumberList.parkSpaceNumber
    this.formList = this.$route.query.alterPlateNumberList
    if (this.$route.query.entranceImg) {
      this.entranceImg = this.$route.query.entranceImg
    }
    if (!this.formList.plateNumber.includes('临时车') && this.formList.plateNumber !== '无牌车') {
      const plateNumberList = this.formList.plateNumber.split('')
      plateNumberList.forEach(item => {
        this.$refs.numInput.setInput(item)
      })
    }
    // 监听安卓软键盘弹出导致的窗口大小变化
    const self = this
    window.onresize = function () {
      // 将滚动轴移到底部
      self.$nextTick(() => {
        const scoll = document.querySelector('.main-content')
        scoll.scrollTo(0, scoll.scrollHeight)
      })
    }
  },
  // 方法集合
  methods: {
    // 确认按钮
    handleClickConfirm () {
      if (!this.entranceImg) {
        Toast('请先上传车牌图片')
      } else if (!this.formList.numberPlateColorCode) {
        Toast('请选择车牌颜色')
      } else if (!this.formList.plateNumber) {
        Toast('请输入车牌号')
      } else if (this.formList.plateNumber === 'fail') {
        Toast('车牌号不能有空格')
      } else if ((this.formList.plateNumber.length === 8 && Number(this.formList.numberPlateColorCode) !== 6)) {
        // 绿牌车选了其他颜色
        Toast('请选择正确的车牌颜色')
      } else if ((this.formList.plateNumber.length === 7 && Number(this.formList.numberPlateColorCode) === 6)) {
        //  不是绿牌车选了绿牌车的编码
        Toast('请选择正确的车牌颜色')
      } else {
        // this.formList.plateScore = 1
        // this.$outCharge.editOrderAndInPicture(this.formList).then(res => {
        //   this.$router.go(-2)
        // })
        if (Number(this.parkTypeCode) === 1) {
          // 车行道
          const info = {
            orderSequence: this.formList.orderSequence,
            plateNumber: this.formList.plateNumber,
            numberPlateColorCode: this.formList.numberPlateColorCode,
            parkSpaceNumber: this.formList.parkSpaceNumber,
            evidenceImgUrl: this.formList.evidenceImgUrl,
            plateScore: 1
          }
          this.$commonAxios.carParkEditOrderAndInPicture(info).then(res => {
            this.$router.go(-2)
          })
        } else {
          console.log('人行道')
          // 人行道
          const info = {
            orderSequence: this.formList.orderSequence,
            plateNumber: this.formList.plateNumber,
            numberPlateColorCode: this.formList.numberPlateColorCode,
            evidenceImgUrl: this.formList.evidenceImgUrl,
            plateScore: 1
          }
          this.$commonAxios.sidewalkParkEditOrderAndInPicture(info).then(res => {
            this.$router.go(-2)
          })
        }
      }
    },
    // 车牌颜色选择器点击
    plateColorClick () {
      this.plateColorShow = true
      this.popShow = false
      this.placeNumberShow = false
    },
    // 选择车牌颜色
    onSelectPlateColor (val) {
      this.plateColorShow = false
      this.plateColor = val.name
      this.formList.plateColor = val.name
      this.formList.numberPlateColorCode = val.code
    },
    // 获取车牌颜色
    queryPlateColor () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2'
          }
        ]
      }
      this.$register.queryPlateColor(info).then((response) => {
        this.plateColorList = response.resultEntity.filter(item => {
          if (item.code !== '4' && item.code !== '5') {
            return item
          }
        })
      })
    },
    // 泊位号点击
    placeNumberClick () {
      this.placeNumberShow = true
      this.plateColorShow = false
      this.popShow = false
    },
    // 选择泊位号 先判断车位有无订单 排除当前车位
    onSelectPlaceNumber (val) {
      if (val.code === this.$route.query.alterPlateNumberList.parkSpaceNumber) {
        this.parkSpaceNumber = val.name
        this.formList.parkSpaceNumber = val.name
        this.formList.parkSpaceNumber = val.code
        this.placeNumberShow = false
      } else {
        // 判断泊位有无订单
        const info = {
          parkSpaceNumber: val.code
        }
        this.$register.queryOrderOnlineByParkSpaceId(info).then(res => {
          if (res.resultEntity) {
            Toast('该泊位已有在停车辆')
          } else {
            this.parkSpaceNumber = val.name
            this.formList.parkSpaceNumber = val.name
            this.formList.parkSpaceNumber = val.code
            this.placeNumberShow = false
          }
        })
      }
    },
    // 获取泊位号
    queryParkPlaceNumber () {
      const info = {
        columnName: ['park_space_id', 'park_space_id'],
        tableName: 'tb_park_space',
        whereStr: [
          {
            colName: 'park_id',
            value: this.$cookie.get('managePark').manageParkId
          },
          {
            colName: 'park_layer_id',
            value: this.$cookie.get('manageParkLayer').manageParkLayerId
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.placeNumberList = res.resultEntity
      })
    },
    // 关闭车牌
    handleClickClose () {
      this.popShow = false
    },
    // 获取到车牌的值
    handleShowPlateNumber (val) {
      this.formList.plateNumber = val
    },
    // 获取车牌输入框的index
    handleClickChangeIndex (val) {
      this.popShow = true
      this.$refs.keyboard.handleTypeChange(val)
    },
    // 键盘点击事件
    handleKeyboardClick (value) {
      this.$refs.numInput.setInput(value) // 将点击的值传入父组件
    },
    // 文件读取完成
    afterRead (file) {
      if (file instanceof Array) {
        file = file[0]
      }
      file.status = 'uploading'
      file.message = '上传中...'
      // 压缩图片
      const self = this
      /* eslint-disable no-new */
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$register.upFile(formData).then(res => {
            file.status = 'done'
            self.entranceImg = ''
            self.entranceImg = res.resultEntity
            self.formList.evidenceImgUrl = res.resultEntity
          }).catch(err => {
            console.log('图片上失败', err)
            file.status = 'failed'
            file.message = '上传失败'
          })
        },
        error (err) {
          file.status = 'failed'
          file.message = '图片压缩失败'
          console.log('压缩失败', err)
        }
      })
    },
    // 文件读取前
    beforeRead (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const res = window.android.startRecognizeWithImage(reader.result)
        if (res !== 'error') {
          this.formList.plateNumber = res
          this.formList.plateNumber.split('').forEach(item => {
            this.handleKeyboardClick(item)
          })
          if (this.formList.plateNumber.trim().length === 7) {
            this.plateColor = '蓝色'
            this.formList.numberPlateColorCode = 2
          } else if (this.formList.plateNumber.trim().length === 8) {
            this.plateColor = '绿色'
            this.formList.numberPlateColorCode = 6
          }
        } else {
          this.$toast('请重新识别')
        }
      }
      reader.onerror = error => {
        console.log(error)
      }
      return true
    },
    // 图片删除
    delImg () {
      this.formList.evidenceImgUrl = ''
    },
    // 返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () {
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.main {
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  overflow-y: hidden;
  .main-content {
    width: 100%;
    height: calc(100% - @navHeight - 70px);
    overflow: auto;
    .main-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        margin-top: 10px;
        padding-bottom: 20px;
        border-radius: 5px;
        background: #ffffff;
        .title {
          width: 95%;
          margin-top: 13px;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }
        .carBG {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          width: 95%;
          height: 245px;
          background: #e7eaee;
          border-radius: 5px;
          overflow: hidden;
          .van-image {
            width: 150px;
            height: 100px;
            margin: 80px 0;
          }
        }
        /deep/ .van-cell {
          width: 95%;
          margin-top: 10px;
          border-radius: 5px;
          background-color: #e7eaee;
        }
        .platInput-box {
          width: 95%;
          margin-top: 10px;
          z-index: 1;
        }

        .platInput-box2 {
          position: fixed;
          bottom: 300px;
          width: 100%;
          margin-top: 10px;
          padding: 10px;
          background: #ffffff;
          box-sizing: border-box;
          z-index: 1;
        }
        .platInput-title {
          position: fixed;
          bottom: 315px;
          width: 95%;
          padding: 10px;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #333333;
          background: #ffffff;
          border-bottom: 1px solid rgb(236, 236, 236);
          z-index: 100;
        }
        .plateColor {
          width: 95%;
          height: 40px;
          margin-top: 10px;
          padding-left: 10px;
          box-sizing: border-box;
          line-height: 40px;
          color: #333333;
          font-size: 15px;
          background: #f5f8fc;
          border-radius: 5px;
        }
        .parkNum {
          width: 95%;
          height: 40px;
          margin-top: 10px;
          background: #f5f8fc;
          border-radius: 5px;
        }
      }
    }
  }
  .but-box {
    width: 93.6%;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin-left: 3.2%;
    margin-top: 20px;
    .van-button {
      width: 100%;
      color: #ffffff;
      font-size: 17px;
      border-radius: 5px;
      background: #19a9fc;
    }
  }
}
.pop-placeholder1 {
  height: 200px;
}
.pop-placeholder2 {
  height: 0px;
}
/deep/.van-uploader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview {
  margin: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview-image {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/deep/.van-uploader__preview-delete {
  width: 20px;
  height: 20px;
}
/deep/.van-uploader__preview-delete-icon {
  top: 0;
  right: 0;
}
.keyboradButton {
  width: 100%;
  height: 250px;
  position: absolute;
  bottom: 50px;
  border-radius: 15px;
  background: #e2e3e7;
  display: block;
}
.carBG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 95%;
  height: 245px;
  background: #e7eaee;
  border-radius: 5px;
  overflow: hidden;
  .van-image {
    width: 150px;
    height: 100px;
    margin: 80px 0;
  }
}
</style>
